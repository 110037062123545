<template>
  <v-select
    v-if="editable"
    v-model="selected"
    solo
    flat
    dense
    outlined
    clearable
    hide-details
    placeholder="옵션 선택"
    label="옵션 선택"
    style="width: 100%;"
    :items="selectOptions"
    :error-messages="selectedError"
    @change="v$.selected.$touch()"
    @blur="v$.selected.$touch()"
  />
  <span v-else>{{ selected }}</span>
</template>

<script>
import { requiredIf } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  name: "SelectFieldItem",
  setup: () => ({ v$: useVuelidate() }),
  props: {
    value: {
      type: Object,
      description: "필드 데이타 (v-model)"
    },
    templateFrame: {
      type: Object,
      required: true,
      description: "필드 속성"
    },
    editable: {
      type: Boolean,
      default: false,
      description: "편집 모드"
    }
  },
  computed: {
    selected: {
      get() {
        return this.value?.value ?? "";
      },
      set(val) {
        this.$emit("input", { value: val });
      }
    },
    selectOptions() {
      return this.templateFrame?.options;
    },
    selectedError() {
      const { $dirty, required } = this.v$.selected;
      const errors = [];
      if (!$dirty) return errors;
      if (required.$invalid) errors.push("필수 입력사항 입니다.");
      return errors;
    }
  },
  methods: {
    async validate() {
      return await this.v$.$validate();
    }
  },
  validations() {
    return {
      selected: {
        required: requiredIf(this.templateFrame?.required)
      }
    };
  },
  mounted() {
    // {props}에서 내려준 {selected}값 있을 경우
    this.$nextTick(() => {
      this.selected = this.value?.value ?? "";
    });
  }
};
</script>

<style lang="scss" scoped></style>
